<template>
  <div class="statisticsTable">
    <div class="filter-block">
      <div class="filter-field">
        <span class="title"> {{ i18n("Custom.CompanyBy") }} </span>
        <!-- <input type="text" v-model="searchInfo"> -->
        <el-select v-model="searchInfo.companyGuid" style="display: block" required>
          <el-option :value="null" :label="i18n('Basic.All')" />

          <el-option
            v-for="company in companyList"
            :key="company.Value"
            :value="company.Value"
            :label="company.Text"
          />

        </el-select>
      </div>
      <div class="filter-field">
        <span class="title">
          {{ i18n("Basic.Department") }} / {{ i18n("Basic.ChineseName") }} /
          {{ i18n("Custom.JobNumber") }} / {{ i18n("Custom.JobTitle") }}</span
        >
        <input type="text" v-model="searchInfo.keyword" />
      </div>

      <!-- 雇用類別 -->
      <div class="filter-field">
        <span class="title"> {{ i18n("Custom.EmploymentCategory") }} </span>
        <!-- <input type="text" v-model="searchInfo"> -->
        <el-select
          clearable
          multiple
          style="display: block" v-model="searchInfo.empStaus" required>
          <el-option :value="0" :label="i18n('Custom.Resign')" />
          <el-option :value="1" :label="i18n('Custom.Stop')" />
          <el-option :value="2" :label="i18n('Custom.Retire')" />
          <el-option :value="3" :label="i18n('Custom.OnTheJob')" />
          <el-option :value="4" :label="i18n('Custom.Interviewers')" />
        </el-select>
      </div>

      <div class="filter-btn-wrap">
        <a class="btn btn-yellow" @click.prevent="getPersonelsys">{{
          i18n("Basic.Search")
        }}</a>
      </div>
    </div>

    <div class="d-flex justify-content-end mb-2">
      <button class="btn btn-sm btn-blue mr-2" @click.prevent="openModal(true)">
        {{ i18n("Basic.Add") }}
      </button>

      <button class="btn btn-sm btn-info mr-2" @click.prevent="downloadEmailExcel">
        {{ i18n("Custom.RemitEmployees") }}Email
      </button>

      <button class="btn btn-sm btn-info mr-2" @click.prevent="openImportEmailModal">
        {{ i18n("Custom.ImportEmployees") }}Email
      </button>

      <button class="btn btn-sm btn-info mr-2" @click.prevent="openImportModal">
        {{ i18n("Custom.ImportPersonnel") }}
      </button>
      <button
        type="button"
        class="btn btn-sm btn-primary"
        @click.prevent="downloadTemplate"
      >
        {{ i18n("Custom.TemplateDownload") }}
      </button>
    </div>

    <vue-bootstrap4-table
      class=""
      :rows="personList"
      :columns="columns"
      :config="config"
      :classes="classes"
    >
      <template slot="employee" slot-scope="props">
        {{ props.row.EmpCName }}/{{ props.row.TitleName }}
      </template>

      <template slot="CreateTime" slot-scope="props">
        {{ dayjs(props.row.CreateTime).format("YYYY-MM-DD HH:mm") }}
      </template>

      <template slot="func" slot-scope="props">
        <button
          class="btn btn-sm btn-blue mr-2"
          @click.prevent="openModal(false, props.row)"
        >
          {{ i18n("Basic.Edit") }}
        </button>
        <button
          v-if="!props.row.IsDeleted && isManager"
          class="btn btn-sm btn-danger"
          @click.prevent="openDelete(props.row)"
        >
          {{ i18n("Custom.Disable") }}
        </button>
      </template>
    </vue-bootstrap4-table>

    <!-- Person Modal -->
    <div class="modal fade" id="infoModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submitPersonelsys)" class="modal-content">
            <div class="modal-header">
              <span v-if="isNew">{{ i18n("Basic.Add") }}</span
              ><span v-if="!isNew">{{ i18n("Basic.Edit") }}</span>
              {{ i18n("Release.EmployeeData") }}
            </div>

            <div class="modal-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="field">
                    <div class="field-name necessary">
                      {{ i18n("Custom.EmploymentStatus") }}
                    </div>
                    <select
                      v-model="tempInfo.EmpStaus"
                      @change="changeEmpStatus"
                      required
                    >
                      <option :value="0">{{ i18n("Custom.Resign") }}</option>
                      <option :value="1">{{ i18n("Custom.Stop") }}</option>
                      <option :value="2">{{ i18n("Custom.Retire") }}</option>
                      <option :value="3">{{ i18n("Custom.OnTheJob") }}</option>
                      <option :value="4">{{ i18n("Custom.Interviewers") }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field">
                    <div
                      class="field-name"
                      :class="{ necessary: tempInfo.EmpStaus !== 4 }"
                    >
                      {{ i18n("Custom.GroupArrivalDate") }}
                    </div>
                    <input
                      type="date"
                      v-model="tempInfo.GroupDate"
                      :required="tempInfo.EmpStaus !== 4"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field" :class="{ 'field-color': tempInfo.EmpStaus === 4 }">
                    <div class="field-name necessary">
                      {{ i18n("Custom.CompanyBy") }}/{{ i18n("Custom.CompanyName") }}
                    </div>
                    <select v-model="tempInfo.CompanyGuid" required @change="getDept(true)">
                      <option
                        v-for="company in companyList"
                        :key="company.Value"
                        :value="company.Value"
                      >
                        {{ company.Text }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field" :class="{ 'field-color': tempInfo.EmpStaus === 4 }">
                    <div class="field-name necessary">
                      {{ i18n("Custom.DepartmentCode") }} /
                      {{ i18n("Custom.ShortName") }}
                    </div>
                    <ValidationProvider
                  name="此欄位"
                  rules="required"
                  v-slot="{ errors }"
                >
                    <el-select
                      style="width: 100%"
                      v-model="tempInfo.DepartmentGuid"
                      placeholder="select"
                      filterable
                      required
                    >
                      <el-option
                        v-for="dept in filterOption"
                        :key="dept.Value"
                        :label="dept.Text"
                        :value="dept.Value"
                      ></el-option>
                    </el-select>
                    <span v-if="errors[0]" class="text-danger font-weight-bold">
                    {{ errors[0] }}
                  </span>
                  </ValidationProvider>

                    <!-- <select v-model="tempInfo.DepartmentGuid" required>
                      <option
                        v-for="dept in filterOption"
                        :key="dept.Value"
                        :value="dept.Value"
                      >
                        {{ dept.Text }}
                      </option>
                    </select> -->
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field">
                    <div
                      class="field-name"
                      :class="{ necessary: tempInfo.EmpStaus !== 4 }"
                    >
                      {{ i18n("Custom.JobNumber") }}
                    </div>
                    <input
                      type="type"
                      name="name"
                      value=""
                      v-model.lazy="tempInfo.EmpNo"
                      :required="tempInfo.EmpStaus !== 4"
                      @input="checkEmpNo"
                      :readonly="tempInfo.EmpStaus === 4"
                      maxlength="8"
                    />
                    <!-- @blur="checkData" -->
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field">
                    <div
                      class="field-name"
                      :class="{ necessary: tempInfo.EmpStaus !== 4 }"
                    >
                      {{ i18n("Custom.IdNumber") }}
                    </div>
                    <input
                      type="type"
                      name="name"
                      value=""
                      v-model="tempInfo.SId"
                      :required="tempInfo.EmpStaus !== 4"
                      minlength="10"
                      maxlength="10"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field" :class="{ 'field-color': tempInfo.EmpStaus === 4 }">
                    <div class="field-name necessary">
                      {{ i18n("Release.EmployeeName") }}
                    </div>
                    <input type="type" name="name" v-model="tempInfo.EmpCName" required />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field">
                    <div class="field-name">
                      {{ i18n("Custom.JobTitleCode") }}
                    </div>
                    <input type="type" name="name" v-model="tempInfo.TitleId" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="field" :class="{ 'field-color': tempInfo.EmpStaus === 4 }">
                    <div
                      class="field-name necessary"

                    >
                      Email({{ i18n('Custom.EmailNote') }})
                    </div>
                    <input
                      type="type"
                      name="name"
                      v-model="tempInfo.Email"
                      required
                    />
                  </div>
                </div>

                <!-- 手機號碼 -->
                <div class="col-md-6">
                  <ValidationProvider class="w-100" v-slot="{ errors }" rules="cellPhone">
                    <div
                      class="field"
                      :class="{ 'field-color': tempInfo.EmpStaus === 4 }"
                    >
                      <div
                        class="field-name"
                        :class="{ necessary: tempInfo.EmpStaus === 4 }"
                      >
                        {{ i18n("Custom.CellPhone") }}
                      </div>
                      <input
                        type="type"
                        :name="i18n('Custom.CellPhone')"
                        v-model="tempInfo.Mobile"
                        :required="tempInfo.EmpStaus === 4"
                      />
                    </div>

                    <span v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>

                <div class="col-md-6">
                  <div class="field">
                    <div class="field-name">{{ i18n("Custom.JobTitle") }}</div>
                    <input type="type" name="name" v-model="tempInfo.TitleName" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="field" :class="{ 'field-color': tempInfo.EmpStaus === 4 }">
                    <div class="field-name necessary">
                      {{ i18n("Basic.Gender") }}
                    </div>
                    <div class="field-container">
                      <input
                        type="radio"
                        name="gender"
                        :value="1"
                        id="male"
                        v-model="tempInfo.Sex"
                        required
                      />
                      <label for="male">{{ i18n("Basic.Male") }}</label>
                      <input
                        type="radio"
                        name="gender"
                        :value="2"
                        id="female"
                        v-model="tempInfo.Sex"
                        required
                      />
                      <label for="female">{{ i18n("Basic.Female") }}</label>
                      <input
                        type="radio"
                        name="gender"
                        :value="3"
                        id="sexother"
                        v-model="tempInfo.Sex"
                        required
                      />
                      <label for="female">{{ i18n("Custom.Other") }}</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field">
                    <div class="field-name">{{ i18n("Basic.Age") }}</div>
                    <input type="type" name="name" v-model="tempInfo.Age" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field">
                    <div class="field-name">{{ i18n("Custom.Seniority") }}</div>
                    <input type="type" name="name" v-model="tempInfo.WorkAge" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field">
                    <div
                      class="field-name"
                      :class="{ necessary: tempInfo.EmpStaus !== 4 }"
                    >
                      {{ i18n("Custom.Birthday") }}
                    </div>
                    <input
                      type="date"
                      v-model="tempInfo.BirthDate"
                      :required="tempInfo.EmpStaus !== 4"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field">
                    <div class="field-name">{{ i18n("Custom.Education") }}</div>
                    <input type="type" name="name" value="" v-model="tempInfo.EduDegre" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field">
                    <div class="field-name">{{ i18n("Custom.School") }}</div>
                    <input
                      type="type"
                      name="name"
                      value=""
                      v-model="tempInfo.SchoolName"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="field">
                    <div class="field-name">{{ i18n("Custom.SectionName") }}</div>
                    <input
                      type="type"
                      name="name"
                      value=""
                      v-model="tempInfo.MajorName"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field">
                    <div
                      class="field-name"
                      :class="{ necessary: tempInfo.EmpStaus !== 4 }"
                    >
                      {{ i18n("Custom.CostCenter") }}
                    </div>
                    <input
                      type="type"
                      name="name"
                      value=""
                      v-model="tempInfo.CostCenter"
                      :required="tempInfo.EmpStaus !== 4"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="field">
                    <div class="field-name">
                      {{ i18n("Custom.PersonnelSubscopeCode") }}
                    </div>
                    <input type="type" name="name" value="" v-model="tempInfo.R2Id" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="field">
                    <div class="field-name">
                      {{ i18n("Custom.PersonnelSubscopeName") }}
                    </div>
                    <input type="type" name="name" value="" v-model="tempInfo.BTEXT" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="field">
                    <div class="field-name">
                      {{ i18n("Custom.ResignationDate") }}
                    </div>
                    <input type="date" v-model="tempInfo.ResignationDate" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="field">
                    <div class="field-name">Level</div>
                    <input type="type" name="name" value="" v-model="tempInfo.Level" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="field">
                    <div class="field-name">TOEIC</div>
                    <input type="type" name="name" value="" v-model="tempInfo.TOEIC" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="field">
                    <div class="field-name">NCLDC</div>
                    <input type="type" name="name" value="" v-model="tempInfo.NCLDC" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="field">
                    <div class="field-name">On-Line Course(MIT Wharton)</div>
                    <input type="type" name="name" value="" v-model="tempInfo.OnLine" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="field">
                    <div class="field-name">English Course</div>
                    <input type="type" name="name" value="" v-model="tempInfo.EngCou" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="field">
                    <div class="field-name">Others</div>
                    <input type="type" name="name" value="" v-model="tempInfo.Others" />
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">
                {{ i18n("Basic.Cancel") }}
              </button>
              <button type="submit" class="btn btn-blue">
                {{ i18n("Basic.Save") }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <!-- Delete Modal -->
    <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            {{ i18n("Custom.DisableThisProfile") }}
          </div>

          <div class="modal-body">
            <div class="alert-box">
              <div class="alert-icon">!</div>
              {{ i18n("Custom.WhetherDisableThisItem") }}
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ i18n("Basic.Cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click.prevent="deletePersonelsys"
            >
              {{ i18n("Custom.Disable") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- check Modal -->
    <div class="modal fade" id="checkModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            {{ i18n("Custom.PersonnelInspection") }}
          </div>
          <div class="modal-body">
            <div class="alert-box">
              {{ i18n("Custom.WhetherCancelPersonnelInformation") }}
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">
              {{ i18n("Basic.Yes") }}
            </button>
            <button type="button" class="btn btn-success" @click="dismissPersonel">
              {{ i18n("Basic.No") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- importPersonModal -->
    <div
      class="modal fade"
      id="importPersonModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">{{ i18n("Custom.ImportPersonnel") }}</div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="field">
                  <div class="field-name mb-2">
                    {{ i18n("Custom.UploadFile") }}
                  </div>
                  <input type="file" ref="files" id="file" />
                </div>
              </div>

              <div class="col-md-6">
                <div class="field">
                  <div class="field-name mb-2">
                    {{ i18n("Custom.whetherToCover") }}
                  </div>
                  <div class="radio-content">
                    <input type="radio" v-model="importInfo.Update" :value="true" />
                    <span class="mr-2">{{ i18n("Basic.Yes") }}</span>
                    <input type="radio" v-model="importInfo.Update" :value="false" />
                    <span>{{ i18n("Basic.No") }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="field">
                  <div class="field-name mb-2">
                    {{ i18n("Custom.ReplyDeletedData") }}
                  </div>
                  <div class="radio-content">
                    <input type="radio" v-model="importInfo.ReActivate" :value="true" />
                    <span class="mr-2">{{ i18n("Basic.Yes") }}</span>
                    <input type="radio" v-model="importInfo.ReActivate" :value="false" />
                    <span>{{ i18n("Basic.No") }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ i18n("Basic.Cancel") }}
            </button>
            <button type="button" class="btn btn-blue" @click.prevent="submitFile">
              {{ i18n("Basic.Confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- importPersonEmailModal -->
    <div
      class="modal fade"
      id="importPersonEmailModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">{{ i18n("Custom.ImportPersonnel") }}Email</div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div style="margin-bottom: 20px">
                  <pre>{{ note }}</pre>
                </div>
              </div>
              <div class="col-md-12">
                <div class="field">
                  <div class="field-name mb-2">
                    {{ i18n("Custom.UploadFile") }}
                  </div>
                  <input type="file" ref="emailFiles" id="emailFiles" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ i18n("Basic.Cancel") }}
            </button>
            <button type="button" class="btn btn-blue" @click.prevent="submitEmailFile">
              {{ i18n("Basic.Confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn-groups {
  // display: block;
  // text-align: right;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table th.click {
  cursor: pointer;
}

.icon {
  display: inline-block;
  margin-left: 6px;
}
.icon.inverse {
  transform: rotate(180deg);
}

.show {
  display: flex;
  align-items: center;

  select {
    margin: 0 6px;
    // margin-bottom: 1rem;
    font-size: 0.9em;
    display: block;
    padding: 3px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  p {
    margin-bottom: 0;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.age_input,
.year_input {
  width: 50px;
}

.entries {
  float: left;
}

#checkModal .modal-dialog {
  top: 50px;
}

.field select {
  padding: 0;
  margin: 0;
}

.modal-body .field > select {
  border: none;
  margin-bottom: 20px;
}

input[type="file"] {
  padding: 0.5rem;
  background-color: white;
  line-height: normal !important;
  height: auto !important;
}

.radio-content {
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: #fff;
  height: 41px;
  padding: 0 1rem;

  input[type="radio"] {
    margin-right: 0.5rem;
  }
}

.field-container {
  width: 100%;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 5px;
  height: 35px;
  line-height: 35px;
  padding: 0 10px;
  font-size: 0.9em;
  border: none;

  display: flex;
  align-items: center;

  label {
    margin: 0 0.5rem;
  }
}

.necessary::before {
  content: "*";
  // font-size: 20px;
  color: red;
}
</style>

<script>
import $ from "jquery";
import { mapGetters, mapActions } from "vuex";
// import PersonSelect from './PersonSelect.vue';

export default {
  // components: {
  //   PersonSelect,
  // },
  data() {
    return {
      isManager: false , //用來判斷登入者是否有權限停用
      screen: null, // loading遮罩
      personList: [],
      config: {
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        per_page: 10,
        per_page_options: [10, 25, 50],
        global_search: {
          visibility: false,
        },
        // checkbox_rows: true,
      },
      classes: {
        table: "table-light table-striped",
        // via: 'table-danger'
      },

      tempInfo: {
        companyGuid: null,
      },
      keepPersonNumber: null,
      Dept: "",
      isNew: false,
      delete: {},
      searchInfo: {
        empStaus:[],
      },
      companyList: [],
      deptList: [],
      status: "新增",
      importInfo: {
        Update: true,
        ReActivate: true,
      },
      invalid: false, //  驗證工號是否為數字
      keepSearchField: null, //查詢的時候把條件存起來

      note: null,
    };
  },
  computed: {
    ...mapGetters("personModule", [
      "persons",
      "post",
      "compName",
      "deptName",
      "r2Id",
      "titleName",
      "options",
    ]),

    columns() {
      const col = [
        {
          label: this.i18n("Custom.CompanyName"),
          name: "CompName",
          sort: true,
        },
        {
          label: this.i18n("Basic.Department"),
          name: "DeptSName",
          sort: true,
        },
        {
          label: this.i18n("Custom.JobNumber"),
          name: "EmpNo",
          sort: true,
        },
        {
          label: this.i18n("Custom.NameAndTitle"),
          name: "Employee",
          slot_name: "employee",
          sort: true,
        },
        {
          label: this.i18n("Custom.WhetherDisableThisItem"),
          name: "IsDeleted",
          sort: true,
        },
        {
          label: this.i18n("Basic.CreateTime"),
          name: "CreateTime",
          sort: true,
          slot_name: "CreateTime",
        },
        {
          label: this.i18n("Custom.Function"),
          name: "func",
          slot_name: "func",
        },
      ];
      return col;
    },

    filterOption() {
      let newOptions = [];
      if (this.tempInfo.EmpStaus === 4) {
        newOptions = this.deptList.filter((item) => {
          // return item.Text.includes("警衛");
          return item.DepartmentCode.slice(0, 2) === "IV";
        });
      } else {
        newOptions = this.deptList.filter((item) => {
          return item.DepartmentCode.slice(0, 2) !== "IV";
        });
      }
      return newOptions;
    },
  },
  // watch: {
  //   // eslint-disable-next-line func-names
  //   'tempInfo.Comp': function () {
  //     //  選擇公司別後抓取部門
  //     this.getDept();
  //     // this.$forceUpdate()
  //   },
  //   tempInfo() {
  //     if (this.tempInfo.LeaderName) {
  //       const word = this.tempInfo.LeaderName.split('/');

  //       this.choiceLeader = {
  //         CompId: this.tempInfo.LeaderCompId,
  //         DeptId: this.tempInfo.LeaderDeptId,
  //         EmpCName: word[0],
  //         TitleName: this.tempInfo.LeaderTitle,
  //       };
  //     }
  //   },
  // },
  methods: {
    ...mapActions("personModule", ["getPerson", "getSetting", "getOption"]),
    ...mapActions(["updateLoading"]),
    ...mapActions("sidebarModule", ["getPending"]),
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },
    getPersonelsys() {
      const url = `${window.BaseUrl.api}/Org/PersonelSys/Search`;
      this.keepSearchField = { ...this.searchInfo };
      this.screen = this.openLoading();
      const params = { ...this.searchInfo };
      this.$http
        .post(url,  params )
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.personList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
    openModal(isNew, item) {
      //  打開人員Modal
      this.isNew = isNew;
      // this.getPerson();
      // this.getSetting();
      // this.getOption();

      // this.getPersonelsysSetting()
      this.getCompany();

      if (isNew) {
        this.tempInfo = {
          DepartmentGuid:null

        };
        this.keepPersonNumber = null;
      } else {
        this.screen = this.openLoading();
        const vm = this;
        const api = `${window.BaseUrl.api}/Org/PersonelSys/${item.Guid}`;
        vm.$http
          .get(api)
          .then((response) => {
            if (response.data.ErrorMessage) {
              this.$notify({
                title: "錯誤",
                message: response.data.ErrorMessage,
                type: "error",
                duration: 0,
              });
            } else {
              this.tempInfo = response.data.Data;
              this.keepPersonNumber = response.data.Data.EmpNo;
              this.getDept();
            }

            this.screen.close();
          })
          .catch((error) => {
            this.$notify({
              title: "錯誤",
              message: error.response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
            this.screen.close();
          });
      }
      $("#infoModal").modal("show");
    },
    submitPersonelsys() {
      //  送出人員資訊
      let url = null;
      let httpMethod = null;
      if (this.isNew) {
        url = `${window.BaseUrl.api}/Org/PersonelSys`;
        httpMethod = "post";
      } else {
        url = `${window.BaseUrl.api}/Org/PersonelSys/${this.tempInfo.Guid}`;
        httpMethod = "put";
      }

      // if(this.tempInfo.Leaderno) {
      this.tempInfo = {
        ...this.tempInfo,
        Mobile: this.tempInfo.Mobile === "" ? null : this.tempInfo.Mobile,
      };

      if (!this.invalid) {
        if (httpMethod === "post") {
          const addUrl = `${window.BaseUrl.api}/Org/PersonelSys/Recruit/Add`;
          this.$http
            .post(addUrl)
            .then((response) => {
              if (response.data.ErrorMessage) {
                this.$notify({
                  title: "錯誤",
                  message: response.data.ErrorMessage,
                  type: "error",
                  duration: 0,
                });
              } else {
                this.$http[httpMethod](url, this.tempInfo)
                  .then((response) => {
                    if (response.data.ErrorMessage) {
                      this.$notify({
                        title: "錯誤",
                        message: response.data.ErrorMessage,
                        type: "error",
                        duration: 0,
                      });
                    } else {
                      $("#infoModal").modal("hide");
                      this.$store.dispatch("alertMessageModule/updateMessage", {
                        message: httpMethod === "post" ? "新增成功" : "編輯成功",
                        status: "success",
                      });
                    }
                    this.getPersonelsys();
                  })
                  .catch((error) => {
                    $("#infoModal").modal("hide");
                    this.$store.dispatch("alertMessageModule/updateMessage", {
                      message: error.response.data.ErrorMessage,
                      status: "danger",
                    });
                  });
              }
              this.screen.close();
            })
            .catch((error) => {
              this.$notify({
                title: "錯誤",
                message: error.response.data.ErrorMessage,
                type: "error",
                duration: 0,
              });
              this.screen.close();
            });
        } else {
          this.$http[httpMethod](url, this.tempInfo)
            .then((response) => {
              if (response.data.ErrorMessage) {
                this.$notify({
                  title: "錯誤",
                  message: response.data.ErrorMessage,
                  type: "error",
                  duration: 0,
                });
              } else {
                $("#infoModal").modal("hide");
                this.$store.dispatch("alertMessageModule/updateMessage", {
                  message: httpMethod === "post" ? "新增成功" : "編輯成功",
                  status: "success",
                });
              }
              this.getPersonelsys();
            })
            .catch((error) => {
              $("#infoModal").modal("hide");
              this.$store.dispatch("alertMessageModule/updateMessage", {
                message: error.response.data.ErrorMessage,
                status: "danger",
              });
            });
        }
      } else {
        alert("工號請勿使用非數字，請重新修改!");
      }

      // } else {
      //     alert('請選擇下關簽核人');
      // }

      this.choiceLeader = {};
    },
    openDelete(item) {
      this.delete = item;
      $("#deleteModal").modal("show");
    },
    deletePersonelsys() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Org/PersonelSys/Deleted/${this.delete.Guid}`;
      this.$http
        .post(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.getPersonelsys();
            $("#deleteModal").modal("hide");
            this.$store.dispatch("alertMessageModule/updateMessage", {
              message: "刪除成功",
              status: "success",
            });
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
    getCompany() {
      const url = `${window.BaseUrl.api}/Org/PersonelSys/Company`;
      this.$http.get(url).then((response) => {
        if (response.data.ErrorMessage) {
          this.$notify({
            title: "錯誤",
            message: response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
        } else {
          this.companyList = response.data.Data;
        }
      });
    },
    getDept(clearDepart) {
      const url = `${window.BaseUrl.api}/Org/PersonelSys/Department/${this.tempInfo.CompanyGuid}`;

      this.screen = this.openLoading();
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            if(clearDepart){
              this.tempInfo.DepartmentGuid=null
            }
            this.deptList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
    dismissPersonel() {
      const url = `${window.BaseUrl.api}/personelsys/dismisspersonel`;

      let comp = "";
      let dept = "";

      if (this.tempInfo.Comp) {
        const id = this.tempInfo.Comp.split("/");
        // eslint-disable-next-line prefer-destructuring
        comp = id[0];
      }

      if (this.tempInfo.Dept) {
        const id = this.tempInfo.Dept.split("/");
        // eslint-disable-next-line prefer-destructuring
        dept = id[0];
      }

      const params = {
        compId: comp,
        deptId: dept,
        empNo: this.tempInfo.EmpNo,
      };

      this.$http
        .get(url, { params })
        .then((response) => {
          if (response.status === 200) {
            // this.tempInfo = response.data

            this.tempInfo = { ...response.data };
            this.tempInfo.Comp = `${this.tempInfo.CompId}/${this.tempInfo.CompName}`;
            this.tempInfo.Dept = `${this.tempInfo.DeptId}/${this.tempInfo.DeptSName}`;
            this.tempInfo.Sex = this.tempInfo.Sex === "1" ? "男" : "女";
            if (this.tempInfo.R2Id && this.tempInfo.BTEXT) {
              this.tempInfo.Range = `${this.tempInfo.R2Id}/${this.tempInfo.BTEXT}`;
            } else if (!this.tempInfo.R2Id && this.tempInfo.BTEXT !== null) {
              this.tempInfo.Range = `${this.tempInfo.BTEXT}`;
            } else if (this.tempInfo.R2Id && this.tempInfo.BTEXT === null) {
              this.tempInfo.Range = `${this.tempInfo.R2Id}`;
            }
            // this.tempInfo.Range = `${this.tempInfo.R2Id}/${this.tempInfo.BTEXT}`
            this.person = this.tempInfo.LeaderName;

            $("#checkModal").modal("hide");
          }
        })
        .catch(() => {
          $("#checkModal").modal("hide");
        });
    },

    openImportModal() {
      const file = document.getElementById("file");
      file.value = "";

      this.importInfo = {
        Update: true,
        ReActivate: true,
      };

      $("#importPersonModal").modal("show");
    },

    openImportEmailModal() {
      const vm = this;
      vm.$refs.emailFiles.value = null;

      $("#importPersonEmailModal").modal("show");
    },
    submitFile() {
      //  匯入人員Excel
      const vm = this;
      const uploadedFile = vm.$refs.files.files[0];
      const url = `${window.BaseUrl.api}/Org/PersonelSys/Import`;
      const formData = new FormData();
      formData.append("File", uploadedFile);
      formData.append("Update", this.importInfo.Update);
      formData.append("ReActivate", this.importInfo.ReActivate);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.screen = this.openLoading();
      this.$http
        .post(url, formData, config)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            // const text = [];

            // text.push(`錯誤筆數有 ${response.data.Data.UpdateRows.length()}筆`);
            // text.push(`成功筆數有 ${response.data.Data.CreateRows.length()}筆`);
            // text.push(`總筆數有 ${response.data.Data.totalRows}筆`);
            this.getPersonelsys();
            $("#importPersonModal").modal("hide");
            alert(`${response.data.Data.Message}`);
          }
          this.screen.close();
        })
        .catch(() => {
          this.$store.dispatch("alertMessageModule/updateMessage", {
            message: "檔案上傳失敗",
            status: "danger",
          });
          $("#importPersonModal").modal("hide");
          this.screen.close();
        });
      // $('#uploadModal').modal('hide')
    },

    //  匯入人員Email
    submitEmailFile() {
      const vm = this;
      const uploadedFile = vm.$refs.emailFiles.files[0];
      const url = `${window.BaseUrl.api}/Org/PersonelSys/ImportEmail`;
      const formData = new FormData();
      formData.append("File", uploadedFile);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.screen = this.openLoading();
      this.$http
        .post(url, formData, config)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            // const text = [];

            // text.push(`錯誤筆數有 ${response.data.Data.UpdateRows.length()}筆`);
            // text.push(`成功筆數有 ${response.data.Data.CreateRows.length()}筆`);
            // text.push(`總筆數有 ${response.data.Data.totalRows}筆`);
            this.getPersonelsys();
            $("#importPersonEmailModal").modal("hide");

            this.$notify({
              title: "匯入結果",
              message:
                '<p style="word-break: break-word;">' +
                response.data.Data.Message +
                "</p>",
              dangerouslyUseHTMLString: true,
              type: "success",
              duration: 0,
            });
            // alert(`${response.data.Data.Message}`);
          }
          this.screen.close();
        })
        .catch(() => {
          this.$store.dispatch("alertMessageModule/updateMessage", {
            message: "檔案上傳失敗",
            status: "danger",
          });
          $("#importPersonModal").modal("hide");
          this.screen.close();
        });
      // $('#uploadModal').modal('hide')
    },
    downloadTemplate() {
      //  下載範例檔
      const url = `${window.BaseUrl.api}/Org/PersonelSys/Import`;
      this.screen = this.openLoading();
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            const a = document.createElement("a"); // Create <a>
            a.href = `data:${response.data.Data.MimeType};base64,${response.data.Data.FileBase64}`; // Image Base64 Goes here
            a.download = response.data.Data.FileName; // File name Here
            a.click(); // Downloaded file
          }
          this.screen.close();
        })
        .catch(() => {
          this.$store.dispatch("alertMessageModule/updateMessage", {
            message: "下載失敗",
            status: "danger",
          });
          this.screen.close();
        });
    },

    //匯出員工Email
    downloadEmailExcel() {
      //  下載範例檔
      const url = `${window.BaseUrl.api}/Org/PersonelSys/Excel`;
      const vm = this;

      let companyCode = null;

      if (vm.keepSearchField.companyGuid) {
        let company = vm.companyList.find((item) => {
          return item.Value === vm.keepSearchField.companyGuid;
        });
        let array = company.Text.split("/");
        companyCode = array[0];
      }

      let params = {
        companyCode: companyCode,
        keyword: vm.keepSearchField.keyword ? vm.keepSearchField.keyword : null,
      };


      this.screen = this.openLoading();
      this.$http
        .get(url, { params, responseType: "blob" })
        .then((response) => {
          const filename = decodeURIComponent(
            decodeURIComponent(
              response.headers["content-disposition"].split("filename*=UTF-8''")[1]
            )
          );

          const blob = new Blob([response.data]);
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
          this.screen.close();
        })
        .catch(() => {
          this.$store.dispatch("alertMessageModule/updateMessage", {
            message: "下載失敗",
            status: "danger",
          });
          this.screen.close();
        });
    },
    // checkData() {
    //   if (
    //     this.tempInfo.Comp
    //     && this.tempInfo.Dept
    //     && this.tempInfo.EmpNo
    //     && this.status === '新增'
    //   ) {
    //     const url = `${window.BaseUrl.api}/personelsys/chkpersonelexist`;

    //     let comp = '';
    //     let dept = '';

    //     if (this.tempInfo.Comp) {
    //       const id = this.tempInfo.Comp.split('/');
    //       // eslint-disable-next-line prefer-destructuring
    //       comp = id[0];
    //     }

    //     if (this.tempInfo.Dept) {
    //       const id = this.tempInfo.Dept.split('/');
    //       // eslint-disable-next-line prefer-destructuring
    //       dept = id[0];
    //     }

    //     const params = {
    //       compId: comp,
    //       deptId: dept,
    //       empNo: this.tempInfo.EmpNo,
    //     };

    //     this.$http
    //       .get(url, { params })
    //       .then((response) => {
    //         if (response.data === 2) {
    //           $('#checkModal').modal('show');
    //         } else if (response.data === 1) {
    //           alert('已有此資料');
    //           $('#infoModal').modal('hide');
    //         }
    //       })
    //       .catch(() => {
    //         // $('#infoModal').modal('hide')
    //       });
    //   }
    // },
    checkEmpNo(e) {
      //  判斷工號是否都為數字
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(e.target.value)) {
        // alert("請輸入數字……")
        this.invalid = true;
      } else {
        this.invalid = false;
      }
    },

    //取得分類
    getNote() {
      this.screen = this.openLoading();
      let params = [];
      const url = `${window.BaseUrl.api}/system/config`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            let arr = [];
            arr = Object.keys(response.data.Data);

            for (let i = 1; i < arr.length; ++i) {
              params.push({
                title: arr[i],
                content: response.data.Data[arr[i]],
              });
            }
          }

          let modal = params.find((item) => {
            return item.title === "人員資料維護";
          });

          let category = modal.content.find((item) => {
            return item.ConfigKey === "ImportHREmailComment";
          });

          this.note = category.Value;

          this.screen.close();
        })
        .catch(() => {
          this.screen.close();
        });
    },

    //雇傭類別切換時
    changeEmpStatus() {
      const vm = this;
      if (vm.tempInfo.EmpStaus === 4) {
        let company = vm.companyList.find((item) => {
          return item.Text.includes("遠東新世紀");
        });
        vm.tempInfo.EmpNo = vm.keepPersonNumber;

        vm.tempInfo.CompanyGuid = company.Value;

        vm.getDept(true);
      }
    },
  },
  created() {
    this.getPersonelsys();
    this.getCompany();
    this.getNote();
  },
  mounted() {
    // 当组件挂载后从 localStorage 中读取值
    this.isManager = JSON.parse(localStorage.getItem('IsManager')) === true;
  }
};
</script>
<style lang="scss">
.field-color {
  .field-container,
  input,
  select,
  .el-select {
    background-color: #fef2cd !important;
  }
  .el-select {
    input {
      background-color: #fef2cd !important;
      border: none;
    }
  }
  &>select{
    background-color: #fef2cd !important;
  }
}
</style>
